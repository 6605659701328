/* global */
* {
  /* font-family: 'Noto Sans', sans-serif; */
  /* font-family: 'Raleway', sans-serif; */
  font-family: 'Source Code Pro', monospace;
  margin: 0px;
}

/* navbar */
.mdNavbar {
  display: none;
}

.navbar {
  background-color: rgb(2, 70, 112);
  border-bottom: 5px solid black;
  color: white;
  position: sticky;
  opacity: 95%;
  top: 0;
  width: 100%;
  z-index: 10;
}

.navbar {
  align-items: center;
  display: flex;
}

.navTitle {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 40px;
  margin-left: 30px;
  width: 100%;
}

.navWrapper {
  display: flex;
  flex: 4;
  justify-content: end;
  list-style: none;
}

.navbar a {
  color: whitesmoke;
  font-size: 20px;
  text-decoration: none;
}

.navLink {
  padding: 20px 20px;
}

.navLink:hover {
  background-color: rgb(0, 133, 177);
  color: black;
}

.menuIcon {
  display: none;
  padding: 5px 20px 0px 20px;
  width: 40px;
}

@media only screen and (max-width: 1024px){
  .smfont {
    font-size: 18px;
  }

  .navTitle {
    font-size: 15px;
  }

  .navLink {
    padding: 20px 15px;
  }

  .navLink a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px){
  .lgNavbar {
    display: none;
  }

  .mdNavbar {
    display: block;
  }
  
  .navWrapper {
    background-color: rgb(2, 70, 112);
    display: none;
    flex-direction: column;
    position: absolute;
    padding: 0px;
    margin: 0px;
    right: 0;
  }

  .menuIcon {
    display: block;
  }
}

/* Banner Section */
.banner {
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  height: 250px;
  padding: 70px 0px 0px 50px;
}

.jobType1 {
  margin-bottom: 20px;
}

.jobType2 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 900px){
  .banner {
    height: 200px;
    padding: 40px 0px 0px 40px;
  }

  .jobType1 {
    font-size: 27px;
  }

  .jobType2 {
    font-size: 27px;
    margin-bottom: 30px;
  }

  .jobSkills {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px){
  .banner {
    height: 160px;
    padding: 30px 20px;
  }

  .jobType1 {
    font-size: 25px;
  }

  .jobType2 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .jobSkills {
    font-size: 20px;
  }
}

/* About me section */
.aboutHeader {
  font-size: 35px;
  margin-bottom: 30px;
  text-align: center;
}

.aboutContent {
  align-items: center;
  display: flex;
  gap: 15%;
  font-size: 20px;
  flex-wrap: column;
  margin: 50px 100px;
}

.aboutLeft {
  flex: 3;
}

.aboutRight {
  display: flex;
  justify-content: center;
  height: 300px;
  width: 300px;
  flex: 2;
}

#slideShow1 {
  border-radius: 2%;
  display: inherit;
  justify-content: space-around;
  /* height: 280px; */
  width: 300px;
}

#slideShow2, #slideShow3, #slideShow4 {
  border-radius: 2%;
  display: none;
  /* height: 350px; */
  /* width: 350px; */
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {opacity: .2}
  to {opacity: 1}
}

@media only screen and (max-width: 1024px){
  .aboutContent {
    margin: 50px 60px;
    gap: 60px;
  }
}

@media only screen and (max-width: 900px){
  .aboutContent{
    flex-direction: column;
    gap: 40px;
  }

  .aboutRight{
    width: 400px;
  }

  #slideShow1, #slideShow2, #slideShow3, #slideShow4{
    height: 300px;
    width: 100%;
  }

  #slideShow1{
    width: 300px;
  }
}

@media only screen and (max-width: 600px){
  .aboutContent {
    margin: 50px 20px;
  }

  .smfont {
    font-size: 17px;
  }

  .aboutRight {
    width: 100%;
  }

  #slideShow1, #slideShow2, #slideShow3, #slideShow4 {
    height: 300px;
  }
}

/* Projects section */
.projectsBanner {
  background: fixed;
  background-size: cover;
  color: rgb(235, 235, 235);
  font-size: 45px;
  padding: 50px 0px;
  text-align: center;
}

.projectLinks {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin: 30px 0px 0px 0px;
}

.linkDiv {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: top;
}

.linkIcon {
  width: 60px;
}

.projectText {
  display: none;
  margin-top: 8px;
  text-align: center;
}

.iconDiv {
  display: flex;
  justify-content: center;
  width: 100px;
  margin-bottom: 40px;
}

.iconDiv:hover {
  margin-bottom: 0px;
}

.iconDiv:hover + .projectText {
  animation: bounce .7s;
  display: block;
  position: relative;
  text-align: center;
}

.project {
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin: 20px 50px 0px 50px;
  padding: 20px;
}

.projectLeft {
  flex: 3;
  font-size: 20px;
  padding: 30px;
}

.projectRight {
  /* padding: 30px 20px; */
  flex: 2;
}

.projectImg {
  /* height: 300px; */
  width: 100%;
}

.projectVid {
  height: 100%;
  width: 100%;
}

.listItem {
  margin-bottom: 10px;
}

.projectDesc {
  margin-bottom: 15px;
}

/* Green Belt Tree Care Project */
.gbtcTitle {
  color: green;
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;
}

/* Outdoor Adventures Project */
.outdoorsTitle {
  color: rgb(0, 0, 207);
  font-size: 35px;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 1200px){
  .projectLeft {
    flex: 1;
  }

  .projectRight {
    flex: 1;
  }

  .gbtcTitle, .outdoorsTitle {
    font-size: 25px;
  }

  .projectDesc {
    font-size: 18px;
  }

  .listItem {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px){
  .projectsBanner {
    font-size: 45px;
    padding: 40px 0px;
  }

  .project {
    flex-direction: column;
    gap: 20px;
    margin: 10px 10px 20px 10px;
  }

  .gbtcTitle, .outdoorsTitle {
    font-size: 30px;
  }

  .projectLeft {
    padding: 0px;
  }

  .iconDiv {
    margin-bottom: 5px;
    width: 80px;
  }

  .iconDiv:hover {
    margin-bottom: 5px;
  }

  .iconDiv:hover + .projectText {
    animation: none;
  }

  .projectText {
    display: block;
    animation: none;
  }
}

@media only screen and (max-width: 600px){
  .projectsBanner {
    font-size: 35px;
    padding: 25px 0px;
  }

  .project {
    margin: 0px 2px 10px 2px;
  }

  .projectLeft {
    margin-bottom: 20px;
  }

  .gbtcTitle, .outdoorsTitle {
    font-size: 24px;
  }

  .projectDesc {
    font-size: 17px;
  }

  .listItem {
    font-size: 17px;
  }

  .projectText {
    font-size: 17px;
  }
}

/* Skills Section */
.skillsBanner {
  background: fixed;
  background-size: cover;
  color: rgb(235, 235, 235);
  font-size: 45px;
  padding: 50px 0px;
  text-align: center;
}

.skillsHeader {
  font-size: 35px;
  margin: 30px 0px;
  text-align: center;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.skillsIcon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 110px;
  justify-content: top;
  width: 120px;
}

.icon {
  height: 70px;
  width: 70px;
}

.skillName {
  display: none;
  margin-top: 8px;
}

.iconDiv:hover + .skillName {
  animation: bounce .7s;
  display: inherit;
  position: relative;
  text-align: center;
}

@keyframes bounce {
  0% {
    margin-top: 0px;
    opacity: 20%;
  }

  20% {
    margin-top: 15px;
    opacity: 100%;
  }

  100% {
    margin-top: 8px;
    opacity: 100%;
  }
}

@media only screen and (max-width: 900px){
  .skillsBanner {
    font-size: 45px;
    padding: 40px 0px;
  }

  .skillsHeader {
    font-size: 25px;
    margin: 15px 0px;
  }
  
  .skills {
    margin-bottom: 20px;
  }
  
  .skillsIcon {
    height: 80px;
    width: 95px;
  }

  .icon {
    height: 50px;
    width: 50px;
  }

  .skillName {
    display: block;
    font-size: 15px;
  }

  .iconDiv:hover + .skillName {
    animation: none;
  }
}

@media only screen and (max-width: 600px){
  .skillsBanner {
    font-size: 35px;
    padding: 25px 0px;
  }

  .skillsIcon {
    margin-bottom: 10px;
  }
}

/* Experience Section */
.experienceBanner {
  background: fixed;
  background-size: cover;
  color: rgb(235, 235, 235);
  font-size: 45px;
  padding: 50px 0px;
  text-align: center;
}

.position {
  margin: 40px 200px;
}

.positionDetails {
  margin: 10px 0px;
  font-size: 20px;
}

@media only screen and (max-width: 1024px){
  .position {
    margin: 40px 100px;
  }
}

@media only screen and (max-width: 900px){
  .experienceBanner {
    font-size: 45px;
    padding: 40px 0px;
  }

  .position {
    margin: 30px 50px;
  }

  .positionTitle {
    font-size: 25px;
  }

  .position h2 {
    font-size: 22px;
  }

  .position h3 {
    font-size: 18px;
  }

  .positionDetails {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px){
  .experienceBanner {
    font-size: 35px;
    padding: 25px 0px;
  }

  .position {
    margin: 20px 20px;
  }

  .positionTitle {
    font-size: 22px;
  }

  .position h2 {
    font-size: 20px;
  }

  .position h3 {
    font-size: 17px;
  }

  .positionDetails {
    font-size: 17px;
  }
}

/* Education Section */
.educationBanner {
  background: fixed;
  background-size: cover;
  color: rgb(235, 235, 235);
  font-size: 45px;
  padding: 50px 0px;
  text-align: center;
}

.school {
  margin: 40px 200px;
}

.educationDetails {
  margin: 10px 0px;
  font-size: 20px;
}

@media only screen and (max-width: 1024px){
  .school {
    margin: 40px 100px;
  }
}

@media only screen and (max-width: 900px){
  .educationBanner {
    font-size: 45px;
    padding: 40px 0px;
  }

  .school {
    margin: 40px 50px;
  }

  .school h1 {
    font-size: 25px;
  }

  .school h3 {
    font-size: 18px;
  }

  .educationDetails {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px){
  .educationBanner {
    font-size: 35px;
    padding: 25px 0px;
  }

  .school {
    margin: 20px 20px;
  }

  .school h1 {
    font-size: 22px;
  }

  .school h3 {
    font-size: 17px;
  }

  .educationDetails {
    font-size: 17px;
  }
}

/* Contact Me Section */
.contactBanner {
  background: fixed;
  background-size: cover;
  color: rgb(235, 235, 235);
  font-size: 45px;
  padding: 50px 0px;
  text-align: center;
}

.contactSection {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 50px;
}

.contactIcon {
  width: 80px;
}

.contactDiv {
  align-items: center;
  width: 90px;
  margin-bottom: 10px;
}

.contactName {
  display: none;
  margin-top: 8px;
  text-align: center;
}

.iconDiv:hover + .contactName {
  animation: bounce .7s;
  display: inherit;
  text-align: center;
}

.footer {
  margin-bottom: 10px;
  text-align: center;
}

@media only screen and (max-width: 900px){
  .contactBanner {
    font-size: 45px;
    padding: 40px 0px;
  }

  .contactDiv {
    margin-bottom: 20px;
    width: 80px;
  }

  .contactSection {
    gap: 20px;
    margin-top: 30px;
  }

  .contactName {
    display: block;
  }

  .iconDiv:hover + .contactName {
    animation: none;
  }
}

@media only screen and (max-width: 600px){
  .contactBanner {
    font-size: 35px;
    padding: 25px 0px;
  }

  .contactSection {
    gap: 5px;
  }

  .contactName {
    font-size: 15px;
  }
}
